import Vue from 'vue'
import Vuex from 'vuex'

import App from './App.vue'

import './assets/scss/main.scss';
import router from './router'

import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";

Vue.config.productionTip = false
Vue.use(VueApollo);
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        view: 'six-col',
        img: true,
        text: false,
        current: '',
        scheme: 'w'
    },
    mutations: {
        visibleText(state) {
            state.text = true
            state.img = false
        },
        visibleImages(state) {
            state.text = false
            state.img = true
        },
        viewTwo(state) {
            state.img = true
            state.text = false
            state.view = 'two-col'
        },
        viewFour(state) {
            state.img = true
            state.text = false
            state.view = 'four-col'
        },
        viewSix(state) {
            state.img = true
            state.text = false
            state.view = 'six-col'
        },
        changeCurrent(state, payload) {
            state.current = payload
        }
    }
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});

let vm = new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
}).$mount('#app');

global.vm = vm; //Define your app variable globally
console.log('%c Designed and developed by Parabol Studio \n https://www.parabolstudio.no (2021) %c', 'background: #ededed; color: #7f00ff');