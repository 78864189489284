<template>
    <nav>
    
        <div class="nav-l">
            <router-link :to="'/'" :class="this.$route.path != '/' ? 'hoverable' : ''">
                <span :class="this.$route.path != '/' ? 'w-arrow' : 'wo-arrow'">Fin Serck–Hanssen</span>
            </router-link>
            <transition name="arrow">
                <span class="arrow" v-show="this.$route.path != '/'">←</span>
            </transition>
    
            <p v-show="changeCurrent" class="current-project">{{ changeCurrent() }}</p>
    
        </div>
    
        <div class="nav-r">
    
            <div class="view-mode">
                <p id="nav-btn" class="nav-btn--inactive" @click="viewTxt">Text</p>
    
                <p v-if="this.$route.name == 'Project'" id="nav-btn" :class="this.$store.state.text == true ? 'nav-btn--inactive' : 'nav-btn--active'" @click="viewImg">Image</p>
    
                <p v-else id="nav-btn" :class="currentView == 'two-col' && this.$store.state.img == true ? 'nav-btn--active':'nav-btn--inactive'" @click="viewAsTwo">2</p>
    
                <p v-if="this.$route.path == '/'" id="nav-btn" class="mob-disabled" :class="currentView == 'four-col' && this.$store.state.img == true ? 'nav-btn--active':'nav-btn--inactive'" @click="viewAsFour">4</p>
    
                <p v-if="this.$route.path == '/'" id="nav-btn" class="mob-disabled" :class="currentView == 'six-col' && this.$store.state.img == true ? 'nav-btn--active':'nav-btn--inactive'" @click="viewAsSix">6</p>
    
                <p v-if="this.$route.path == '/'" id="nav-btn" @click.self="randomizeLander" class="nav-btn--inactive">Shuffle</p>
            </div>
    
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        currentObjectTitle: String
    },
    computed: {
        currentView() {
            return this.$store.state.view
        }
    },
    methods: {
        changeCurrent() {
            return this.$store.state.current
        },
        checkNav() {
            const navBtns = document.querySelectorAll('#nav-btn')
            navBtns.forEach(x => {
                x.classList.remove('nav-btn--active')
                x.classList.add('nav-btn--inactive')
            })
        },
        viewAsTwo(target) {
            this.checkNav();
            const element = target.target
            if (element.classList.contains('nav-btn--inactive')) {
                element.classList.remove('nav-btn--inactive')
                element.classList.add('nav-btn--active')
            } else {
                element.classList.add('nav-btn--active')
            }
            this.$store.commit('viewTwo')
        },
        viewAsFour(target) {
            this.checkNav();
            const element = target.target
            if (element.classList.contains('nav-btn--inactive')) {
                element.classList.remove('nav-btn--inactive')
                element.classList.add('nav-btn--active')
            } else {
                element.classList.add('nav-btn--active')
            }

            this.$store.commit('viewFour')
        },
        viewAsSix(target) {
            this.checkNav();
            const element = target.target
            if (element.classList.contains('nav-btn--inactive')) {
                element.classList.remove('nav-btn--inactive')
                element.classList.add('nav-btn--active')
            } else {
                element.classList.add('nav-btn--active')
            }

            this.$store.commit('viewSix')
        },
        viewTxt(target) {
            this.checkNav();
            const element = target.target
            if (element.classList.contains('nav-btn--inactive')) {
                element.classList.remove('nav-btn--inactive')
                element.classList.add('nav-btn--active')
            } else {
                element.classList.add('nav-btn--active')
            }
            window.scroll({
                top: 0,
            });
            this.$store.commit('visibleText')
        },
        viewImg(target) {
            this.checkNav();
            const element = target.target
            if (element.classList.contains('nav-btn--inactive')) {
                element.classList.remove('nav-btn--inactive')
                element.classList.add('nav-btn--active')
            } else {
                element.classList.add('nav-btn--active')
            }
            window.scroll({
                top: 0,
            });
            this.$store.commit('visibleImages')
        },
        // Used to focus the input from the parent
        randomizeLander() {
            this.$root.$emit('update', 'Randomize!')
        }
    },
    mounted() {
        window.addEventListener("keyup", event => {
            if (event.code === 'Escape') {
                this.$router.push({ name: 'Lander' });
            }
            if (event.code === 'KeyT') {
                this.$store.commit('visibleText')
            }
            if (event.code === 'Digit2') {
                this.$store.commit('viewTwo')
            }
            if (event.code === 'Digit4') {
                this.$store.commit('viewFour');
            }
            if (event.code === 'Digit6') {
                this.$store.commit('viewSix')
            }
        });
    }
}
</script>