import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Lander',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "main" */ '@/views/Lander.vue')
    },
    {
        path: '/projects/:slug',
        name: 'Project',
        component: () => import( /* webpackChunkName: "works" */ '@/views/Project.vue'),
    },
    {
        path: '/404',
        'name': '404',
        component: () => import( /* webpackChunkName: "main" */ "@/components/404.vue"),
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes
})

export default router