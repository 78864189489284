<template>
    <div id="fsh-app">
        <Nav />
        <main>
            <router-view/>
        </main>
        <Footer />
    </div>
</template>

<script>
import Nav from './components/Nav'
import Footer from './components/Footer.vue'

export default {
    name: 'App',
    data() {
        return {
            object: {
                title: ''
            },
            api_url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    components: {
        Nav,
        Footer
    }
}
</script>
