<template>
    <footer>
        <p>
            Fin Serck–Hanssen ©{{ new Date().getFullYear() }}. Designed and developed by </p><a href="https://parabolstudio.no" target="_blank" rel="noreferrer noopener">Parabol Studio</a>
    </footer>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>